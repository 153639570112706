jQuery.fn.extend({
    checkAllEntries: function(){
        var me = this;

        $(document).on('change', '#' + this.attr('id'), function(e) {
            if (me.is(':checked') === true) {
                $('input[name^=' + me.data('tasks') + ']').not(':checked').click();
            } else {
                $('input[name^=' + me.data('tasks') + ']').attr('checked', false);
            }
        });
    }
});

var dateTimePickerOptions = {
    lang:'es',
    i18n:{
        es:{
            months:[
                'Enero','Febrero','Marzo','Abril',
                'Mayo','Junio','Julio','Agosto',
                'Septiembre','Octubre','Noviembre','Deciembre',
            ],
            dayOfWeek:[
                "Do", "Lu", "Ma", "Mi", 
                "Ju", "Vi", "Sa",
            ]
        }
    },
    closeOnTimeSelect: true,
    timepicker: true,
    format:'Y-m-d H:i'
};

var datePickerOptions = {
    lang:'es',
    i18n:{
        es:{
            months:[
                'Enero','Febrero','Marzo','Abril',
                'Mayo','Junio','Julio','Agosto',
                'Septiembre','Octubre','Noviembre','Deciembre',
            ],
            dayOfWeek:[
                "Do", "Lu", "Ma", "Mi", 
                "Ju", "Vi", "Sa",
            ]
        }
    },
    closeOnDateSelect: true,
    timepicker: false,
    format:'Y-m-d'
};

var allowTimesArr = [
        '00:00', '01:00', '02:00', '03:00', '04:00', 
        '05:00', '06:00', '07:00', '08:00', '09:00', 
        '10:00', '11:00', '12:00', '13:00', '14:00', 
        '15:00', '16:00', '17:00', '18:00', '19:00', 
        '20:00', '21:00', '22:00', '23:00', '23:59', 
    ];
var timePickerOptions = {
    datepicker:false,
    format:'H:i',
    allowTimes:allowTimesArr
};

Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
    switch (operator) {
        case '==':
            return (v1 == v2) ? options.fn(this) : options.inverse(this);
        case '===':
            return (v1 === v2) ? options.fn(this) : options.inverse(this);
        case '<':
            return (v1 < v2) ? options.fn(this) : options.inverse(this);
        case '<=':
            return (v1 <= v2) ? options.fn(this) : options.inverse(this);
        case '>':
            return (v1 > v2) ? options.fn(this) : options.inverse(this);
        case '>=':
            return (v1 >= v2) ? options.fn(this) : options.inverse(this);
        case '&&':
            return (v1 && v2) ? options.fn(this) : options.inverse(this);
        case '||':
            return (v1 || v2) ? options.fn(this) : options.inverse(this);
        default:
            return options.inverse(this);
    }
});

$(function() {
	// close alert-box after 5 seconds.
	window.setTimeout(function() {
		$(".alert-box a.close").trigger("click.fndtn.alert");
	}, 10000);

	$('.datepicker').datetimepicker(datePickerOptions);

    $('.datetimepicker').datetimepicker(dateTimePickerOptions);

    $('.timepicker').datetimepicker(timePickerOptions);

    /*$(document).on('click', '.destroy-reveal-modal', function(e) {
        e.preventDefault();

        var me = $(this);
        fzbox.confirm('Estas seguro de eliminar esta entrada?', function(result) {
            if (result) {
                $.ajax({
                    url: me.attr('href'),
                    type: 'DELETE',
                    dataType: 'JSON',
                    success: function(data) {
                        if (data.status == 'success')
                            me.closest('tr').remove();

                        $("#ajax-result").addClass(data.status);
                        $("#ajax-result").find('span').html(data.message);
                        $("#ajax-result").removeClass('hide');
                    }
                }); 
            }
        });
    });*/

    $(document).on('click', '.ban-reveal-modal', function(e) {
        e.preventDefault();

        var me = $(this), statusCol = $(this).closest('tr').find('.col-status').find('i');
        fzbox.confirm('Estas seguro de activar/desactivar esta entrada?', function(result) {
            if (result === true) {
                $.ajax({
                    url: me.attr('href'),
                    type: 'POST',
                    dataType: 'JSON',
                    success: function(data) {
                        if (statusCol.hasClass('success')) {
                            statusCol.removeClass('success').addClass('alert');
                        } else {
                            statusCol.removeClass('alert').addClass('success');
                        }

                        $("#ajax-result").addClass(data.status);
                        $("#ajax-result").find('span').html(data.message);
                        $("#ajax-result").removeClass('hide');
                    }
                });
            }
        });
    });
    
    $(document).on('click', 'button[type="button"]', function(e) {
        var href = $(this).data('href');
        if (typeof href !== 'undefined') {
            window.location.href = href;
        }
    });
});
