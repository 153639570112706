dataTableDefaults = {
    pagingType: "full_numbers",
    language: {
        url: "/js/dataTables.spanish.lang"
    },
    initComplete: function () {
        this.api().columns().every( function () {
            var column = this;
            var select = $('<select><option value=""></option></select>')
                .appendTo( $(column.footer()).empty() )
                .on( 'change', function () {
                    var val = $.fn.dataTable.util.escapeRegex(
                        $(this).val()
                    );

                    column
                        .search( val ? '^'+val+'$' : '', true, false )
                        .draw();
                } );

            column.data().unique().sort().each( function ( d, j ) {
                select.append( '<option value="'+d+'">'+d+'</option>' )
            });
        });
    },
    columnDefs: [
        { targets: 'no-sort', orderable: false }
    ],
    lengthMenu: [[15, 25, 50, -1], [15, 25, 50, "Todos"]]
};

function OwlDataTable() {
    this.oTable = null;

    this.options = dataTableDefaults;

    this.clear = function() {
        if (typeof this.oTable.rows !== 'undefined')
            this.oTable.rows.remove().draw();
    };

    this.setOptions = function(customOptions) {
        $.extend(this.options, customOptions);
    };

    this.draw = function(dom) {
        this.oTable = $(dom).dataTable(this.options);
    };

    me = this;
    $(document).on('click', '.owl-datatable #check-all-entries', function() {
        $('input', me.oTable.fnGetNodes()).prop('checked', $(this).prop('checked'));
    });

    me = this;
    $('.owl-datatable #check-all-entries').closest('form').bind('submit', function(e) {
        console.log('hello');
        var rows = me.oTable.fnGetNodes(), inputs = [];

        // Add the inputs which are not currently visible to the form
        for ( var i = 0, len = rows.length; i < len; i++) {
            var $fields = $(rows[i]).find('input[name]:hidden:checked');

            $fields.each(function (idx, el) {
                inputs.push('<input type="hidden" name="' + $(el).attr('name') + '" value="' + $(el).val() +'">');
            });
        }

        $(this).append( inputs.join(''));
    });
}