var fzbox = {
	buttons: {
	},
	title: '',
	alert: function(message) {
		var id = this.generateId(8);
		var revealModal = '<div class="reveal-modal" id="' + id + '" data-reveal> \
<div class="modal-body"> \
	<p>' + message + '</p> \
</div> \
<hr> \
<div class="modal-footer"> \
	<div class="row"> \
		<div class="small-12 medium-2 medium-offset-10"> \
			<ul class="button-group even-1"> \
				<li><a href="#" class="button primary" data-fb-handler="confirm">Aceptar</a></li> \
			</ul> \
		</div> \
	</div> \
</div> \
<a class="close-reveal-modal">&#215;</a> \
</div>';
		
		$('body').append(revealModal);

		$('#' + id).foundation('reveal', 'open');

		var me = this;
		$('#' + id).on('click', '.modal-footer .button', function(e) {
			$('.reveal-modal').foundation('reveal','close');
		});
	},
	confirm: function (message, fn) {
		var id = this.generateId(8);
		var revealModal = '<div class="reveal-modal" id="' + id + '" data-reveal> \
<div class="modal-body"> \
	<p>' + message + '</p> \
</div> \
<hr> \
<div class="modal-footer"> \
	<div class="row"> \
		<div class="small-12 medium-4 medium-offset-8"> \
			<ul class="button-group even-2"> \
				<li><a href="#" class="button secondary" data-fb-handler="cancel">Cancelar</a></li> \
				<li><a href="#" class="button primary" data-fb-handler="confirm">Aceptar</a></li> \
			</ul> \
		</div> \
	</div> \
</div> \
<a class="close-reveal-modal">&#215;</a> \
</div>';
		
		if ($('body').find('#' + id).length == 0)
			$('body').append(revealModal);

		$('#' + id).foundation('reveal', 'open');

		var me = this;
		$('#' + id).on('click', '.modal-footer .button', function(e) {
			$('.reveal-modal').foundation('reveal','close');

			result = $(this).data('fb-handler') == "confirm" ? true : false;
			fn(result);
		});
   },
   dialog: function(arg) {
   		var id = this.generateId(8);
		var revealModal = '<div class="reveal-modal" id="' + id + '" data-reveal> \
<div class="modal-body">';
		revealModal += (arg.title) ? '<h4 class="text-center">' + arg.title + '</h4>' : '';
		revealModal += '<p>' + arg.message + '</p> \
</div> \
<hr> \
<div class="modal-footer"> \
	<div class="row"> \
		<div class="small-12 medium-2 medium-offset-10"> \
			<ul class="button-group even-1"> \
				<li><a href="#" class="button primary" data-fb-handler="confirm">Aceptar</a></li> \
			</ul> \
		</div> \
	</div> \
</div> \
<a class="close-reveal-modal">&#215;</a> \
</div>';

		$('body').append(revealModal);

		$('#' + id).foundation('reveal', 'open');

		var me = this;
		$('#' + id).on('click', '.modal-footer .button', function(e) {
			$('.reveal-modal').foundation('reveal','close');
		});
   },
   findHandler: function (name) {},
   generateId: function (length) {
   		var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');

	    if (! length) {
	        length = Math.floor(Math.random() * chars.length);
	    }

	    var str = '';
	    for (var i = 0; i < length; i++) {
	        str += chars[Math.floor(Math.random() * chars.length)];
	    }

	    return str;
   }
};