;(function(global) {
    'use strict';


    var oMap = function(ops) {
        return new oMap.init(ops);
    };

    var mapCenter = {
            lat: -31.325487,
            lng: -70.927734
        },
        map          = null,
        mapContainer = 'map-canvas',
        markers      = {},
        eventMarkers = {},
        queueCord    = [],
        queueOffset  = 0,
        route        = null,
        routeColor   = '#82FA58',
        routeCords   = [],
        zoom         = 5,
        paused       = false,
        icon         = {
            path     : google.maps.SymbolPath.BACKWARD_CLOSED_ARROW,
            scale    : 5,
            rotation : 0
        },
        infowindow = new google.maps.InfoWindow(),
        timer,
        firstPoint,
        lastPoint,
        iconBase = 'http://maps.google.com/mapfiles/kml/',
        travelBounds = new google.maps.LatLngBounds(),
        markerClusterer,
        geoArray = [],
        shapeOptions = {
            strokeColor: '#5454ff',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#5454ff',
            fillOpacity: 0.35
        };


    function cleanUpMap() {
        $.each(geoArray, function(index, value) {
        value.setMap(null);
        });
    }

    function mkLatLng (lat, lng) {
        lat = parseFloat(lat);
        lng = parseFloat(lng);
        if (isNaN(lat) || isNaN(lng)) {
            throw "invalid data";
        }

        // Now passing in TWO NUMERIC ARGUMENTS, as per the API
        return new google.maps.LatLng(lat, lng);
    }

    function drawGeofence(geofence) {
        cleanUpMap();

        var bounds;
        switch (geofence.type) {
            case 'polygon':
                if (geofence.path.length === 0) {
                    break;
                }
                var polygonCoords = [];
                var entries = geofence.path[0];

                bounds = new google.maps.LatLngBounds();
                for (var i=0; i<entries.length; i++) {
                    var coords = mkLatLng(entries[i][0], entries[i][1]);
                    polygonCoords.push(coords);
                    bounds.extend(coords);
                }

                shapeOptions.strokeColor = '#' + geofence.color;
                shapeOptions.fillColor = '#' + geofence.color;

                var polygon = new google.maps.Polygon(shapeOptions);
                polygon.setPath(polygonCoords);
                polygon.setMap(map);
                    
                geoArray.push(polygon);
                break;
            case 'circle':
                var circle = new google.maps.Circle(shapeOptions);
                circle.setCenter(mkLatLng(geofence.lat, geofence.lon));
                circle.setRadius(geofence.radius);
                circle.setMap(map);
                bounds = circle.getBounds();

                geoArray.push(circle);
                break;
            default:
                break;
        }

        map.fitBounds(bounds);
        map.setCenter(bounds.getCenter());
        map.setZoom(Math.min(16, map.getZoom()));
    }

    oMap.prototype = {

        dequeue: function() {

            var self = this;

            if (queueCord.length === 0) return undefined;

            var item = queueCord[queueOffset];

            if (++queueOffset * 2 >= queueCord.length) {
                queueCord = queueCord.slice(queueOffset);
                queueOffset = 0;
            }

            return item;

        },

        inicialize: function() {

            var self = this;

            // Solo se permite una inicialización
            if (map !== null) {
                return self;
            }

            var options = {
                zoom   : zoom,
                center : new google.maps.LatLng(-33.448575, -70.661376),
            };
            map = new google.maps.Map(document.getElementById(mapContainer), options);

            var geofencesLayer = new google.maps.Data();

            geofencesLayer.loadGeoJson(baseURL + 'ajax/geofence/onmap');

            markerClusterer = new MarkerClusterer(map, geofencesLayer, {imagePath: '../images/m'});

            this.drawMarkers(geofencesLayer, map, markerClusterer);

            google.maps.event.addListener(map, "click", function () {
                infowindow.close();
            });

            return self;
        },

        addEvent: function(marker) {
            var self = this;

            marker.setMap(map);
        },

        updMarker: function(marker, latitude, longitude, direction, center, pos, newIcon, info) {
            var self = this;

            var lat = parseFloat(latitude)  || 0.0;
            var lng = parseFloat(longitude) || 0.0;
            var dir = parseInt(direction)   || 0;
            var cen = center === true;
            var position = pos || new google.maps.LatLng(lat, lng);

            if (typeof markers[marker] === 'undefined') {
                markers[marker] = new google.maps.Marker({
                    position: position,
                    map: map,
                    icon: newIcon || icon
                });
            } else {
                markers[marker].setPosition(position);
                icon.rotation = (dir + 180) % 360;
                markers[marker].setIcon(icon);
                if (markers[marker].getMap() === null) {
                    markers[marker].setMap(map);
                }
            }

            if (typeof map.getBounds() !== 'undefined') {
                if (!map.getBounds().contains(markers[marker].getPosition())) {
                    map.setCenter(markers[marker].getPosition());
                }
            }

            if (marker == 'event') {
                infowindow.setPosition(markers[marker].position);
                infowindow.setContent(info);
                infowindow.open(map);
            }

            return self;

        },

        drawMarkers: function(layer, map, cluster) {
            google.maps.event.addListener(layer, 'addfeature', function (e) {
                if (e.feature.getGeometry().getType() === 'Point') {
                    var id, type;
                    id = e.feature.getProperty('id');
                    type = e.feature.getProperty('type');

                    var marker = new google.maps.Marker({
                        position: e.feature.getGeometry().get(),
                        title: e.feature.getProperty('name'),
                        icon: {url: e.feature.getProperty('icon')},
                        map: map,
                        html: e.feature.getProperty('html'),
                        id: e.feature.getProperty('id'),
                    });
                    var geofence = {
                        type: e.feature.getProperty('type'),
                        path: e.feature.getProperty('path'),
                        radius: e.feature.getProperty('radius'),
                        color: e.feature.getProperty('color'),
                        lat: e.feature.getProperty('lat'),
                        lon: e.feature.getProperty('lon'),
                    };

                    google.maps.event.addListener(marker, 'click', function (marker, e) {
                        return function () {
                            var id = e.feature.getProperty('id');
                            drawGeofence(geofence);

                            infowindow.setPosition(e.feature.getGeometry().get());
                            infowindow.setContent(e.feature.getProperty('html'));
                            infowindow.open(map);
                        };
                    }(marker, e));

                    cluster.addMarker(marker);
                }
            });

            google.maps.event.addListener(map, "click", function () {
                selectedMarkerId = null;
            });

            layer.setMap(null);
        },


        drawPath: function() {

            var self = this;

            if (route === null) {

                route = new google.maps.Polyline({
                    path          : routeCords,
                    geodesic      : true,
                    strokeColor   : routeColor,
                    strokeOpacity : 1.0,
                    strokeWeight  : 6
                });

                route.setMap(map);
                routeCords = route.getPath();
            }

            return self;
        },

        addBoundedMarkers: function() {
            var startMarker = new google.maps.Marker({
                    position: new google.maps.LatLng(firstPoint.lat, firstPoint.lng),
                    icon: iconBase + 'pal3/icon63.png',
                    map: map,
                });
            markers['startMarker'] = startMarker;

            var endMarker = new google.maps.Marker({
                position: new google.maps.LatLng(lastPoint.lat, lastPoint.lng),
                icon: iconBase + 'pal4/icon21.png',
                map: map,
            });

            markers['endMarker'] = endMarker;
        },

        drawItStatically: function(named) {
            var newPos = {};

            while (queueCord.length > 0) {

                var cord = self.dequeue();

                if (named) {
                    newPos.lat = parseFloat(cord.lat);
                    newPos.lng = parseFloat(cord.lng);
                    newPos.dir = parseInt(cord.heading);
                } else {
                    newPos.lat = parseFloat(cord[1]);
                    newPos.lng = parseFloat(cord[0]);
                    newPos.dir = parseInt(cord[2]);
                }

                newPos.mapObject = new google.maps.LatLng(newPos.lat, newPos.lng);
                travelBounds.extend(newPos.mapObject);

                routeCords.push(newPos.mapObject);
            }

            map.fitBounds(travelBounds);
        },

        drawItDynamically: function(delay, named, pCenter, callback) {

            var self = this;

            if (paused) {
                setTimeout(function() {self.drawItDynamically(delay, named, pCenter, callback);}, delay);

                return self;
            }

            var cord = self.dequeue();

            if (typeof cord === 'undefined') {
                return;
            }

            var newPos = {};

            if (named) {
                newPos.lat = parseFloat(cord.lat);
                newPos.lng = parseFloat(cord.lng);
                newPos.dir = parseInt(cord.heading);
            } else {
                newPos.lat = parseFloat(cord[1]);
                newPos.lng = parseFloat(cord[0]);
                newPos.dir = parseInt(cord[2]);
            }

            newPos.mapObject = new google.maps.LatLng(newPos.lat, newPos.lng);

            routeCords.push(newPos.mapObject);

            self.updMarker('end', newPos.lat, newPos.lng, newPos.dir, pCenter);

            travelBounds.extend(newPos.mapObject);


            if (typeof compass !== 'undefined') {
                compass.draw(newPos.dir);
            }

            // Cuando ya no hay puntos se detiene
            if (queueCord.length === 0) {
                window.clearTimeout(self.timer);
                callback();

                // map.fitBounds(travelBounds);

                return self;
            }

            self.timer = window.setTimeout(function() {self.drawItDynamically(delay, named, pCenter, callback);}, delay);

            return self;
        },

        addPathCords: function(cords, named, delay, pCenter, callback) {
            var self = this;
            var isNamed = named === true;

            //clean up the map.
            self.cleanUpMap();

            paused = false;

            if (typeof cords === 'undefined') {
                return self;
            }

            queueCord = queueCord.concat(cords);
            if (queueCord.length === 0) {
                return self;
            }

            if (route === null) {
                self.drawPath();
            }

            // Get the first and last point in order to draw them as markers on the map.
            firstPoint = cords[0];
            lastPoint  = cords[cords.length-1];

            // Reset the original array.
            cords.length = 0;

            map.setCenter(new google.maps.LatLng(firstPoint.lat, firstPoint.lng));

            // dynamic drawing.
            if (parseInt(delay) > 0) {
                self.drawItDynamically(delay, named, pCenter, callback);
            } else {
                self.drawItStatically(named);
            }

            self.addBoundedMarkers();


            return self;
        },

        delPathCords: function() {

            var self = this;

            queueCord = [];
            if (typeof routeCords === 'object' && Array.isArray(routeCords) === false) {
                routeCords.clear();
            }

            return self;
        },

        delMarkers: function() {

            var self = this;

            Object.getOwnPropertyNames(markers).forEach(function(val, idx, array) {
                markers[val].setMap(null);
            });

            return self;
        },

        cleanUpMap: function() {

            var self = this;

            self.delPathCords();
            self.delMarkers();
        },


        stop: function(callback) {

            var self = this;

            self.cleanUpMap();
            paused = false;

            callback();

            return self;
        },

        pause: function() {

            var self = this;

            paused = !paused;

            return self;
        },

        info : function() {

            var self = this;

            return self;
        }
    };

    oMap.init = function(ops) {

        var ops  = ops || {};
        var self = this;

        self.mapCenter    = ops.center       || mapCenter;
        self.mapContainer = ops.mapContainer || mapContainer;
        self.zoom         = ops.zoom         || zoom;

        self.inicialize();
    };

    oMap.init.prototype = oMap.prototype;

    global.oMap = oMap;

})(window);
