function Circle (center, radius) {
	this.center = typeof center !== 'undefined' ? { lat: center.lat, lng: center.lng } : { lat: 0.00, lng: 0.00 };
	this.radius = typeof radius !== 'undefined' ? radius : 0.00;
	this.fromGoogleCircle = function(circle) {
		var center = circle.getCenter();

		this.center = { lat: center.lat(), lng: center.lng() };
		this.radius = circle.getRadius();
	};
	this.toJson = function() {
		return JSON.stringify({center: this.center, radius: this.radius });
	};
}

function Rectangle (northEast, southWest) {
	this.northEast = typeof northEast !== 'undefined' ? { lat: northEast.lat, lng: northEast.lng } : { lat: 0.00, lng: 0.00 };
	this.southWest = typeof southWest !== 'undefined' ? { lat: southWest.lat, lng: southWest.lng } : { lat: 0.00, lng: 0.00 };
	this.fromGoogleRectangle = function(rectangle) {
		var rectangleBounds = rectangle.getBounds(),
			rectangleNorthEast = rectangleBounds.getNorthEast(),
			rectangleSouthWest = rectangleBounds.getSouthWest();
		this.northEast = { lat: rectangleNorthEast.lat(), lng: rectangleNorthEast.lng() };
		this.southWest = { lat: rectangleSouthWest.lat(), lng: rectangleSouthWest.lng() };
	};
	this.toJson = function() {
		return JSON.stringify({northEast: this.northEast, southWest: this.southWest });
	};
}

function Polygon (path) {
	this.path = typeof path !== 'undefined' ? path : [];
	this.fromGooglePolygon = function(polygon) {
		me = this;
		polygon.getPath().forEach(function(item, index) {
			me.path.push({ lat: item.lat(), lng: item.lng() });
		});
	};
	this.toJson = function() {
		return JSON.stringify(this.path);
	};
}

function Polyline(path) {
	this.path = typeof path !== 'undefined' ? path : [];
	this.fromGooglePolyline = function(polyline) {
		me = this;
		polyline.getPath().forEach(function(item, index) {
			me.path.push({ lat: item.lat(), lng: item.lng() });
		});
	};
	this.toJson = function() {
		return JSON.stringify(this.path);
	};
}

function updateDrawingMode(manager, type) {
	var drawingMode = google.maps.drawing.OverlayType.POLYLINE;
	
	switch (type) {
		case 'polygon':
			drawingMode = google.maps.drawing.OverlayType.POLYGON;
			break;
		case 'circle':
			drawingMode = google.maps.drawing.OverlayType.CIRCLE;
			break;
		case 'rectangle':
			drawingMode = google.maps.drawing.OverlayType.RECTANGLE;
			break;
		default:
			drawingMode = google.maps.drawing.OverlayType.POLYLINE;
	}

	manager.setOptions({ drawingMode: drawingMode });
}

function OwlDrawingManager(map) {
	this.map = map;
	this.manager;
	this.allOverlays = [];
	this.selectedShape;
	this.polyOptions = {
		editable: true,
		geodesic: true,
		strokeColor: '#111472',
		strokeWeight: 8,
		strokeOpacity: 0.4
	};
	this.drawingOptions = {
		drawingMode: null,
		drawingControl: true,
		drawingControlOptions: {
			drawingModes : [
				google.maps.drawing.OverlayType.CIRCLE,
				google.maps.drawing.OverlayType.POLYGON,
				// google.maps.drawing.OverlayType.POLYLINE,
				// google.maps.drawing.OverlayType.RECTANGLE,
				// google.maps.drawing.OverlayType.MARKER
			]
		},
		circleOptions: this.polyOptions,
		polygonOptions: this.polyOptions,
		polylineOptions: this.polyOptions,
		rectangleOptions: this.polyOptions,
		map: this.map
	};
	this.manager;
	this.init = function() {
		this.manager = new google.maps.drawing.DrawingManager(this.drawingOptions);
		// console.log(this.manager);
	};
	this.clearSelection = function() {
		if (this.selectedShape) {
			this.selectedShape.setEditable(false);
			this.selectedShape = null;
		}
	};
	this.setSelection = function(shape) {
		this.clearSelection();
		this.selectedShape = shape;
		if (shape.type != 'marker') {
			shape.setEditable(true);
		}
	};
	this.deleteSelectedShape = function() {
		if (this.selectedShape) {
			this.selectedShape.setMap(null);
		}
	};
	this.deleteAllShape = function() {
		for (var i = 0; i < this.allOverlays.length; i++) {
			this.allOverlays[i].overlay.setMap(null);
		}

		this.allOverlays = [];
	};

	this.init();
}

function MarkerHandler(map) {
	this.markers = [],
	this.map = map;
	this.icon = {
		path: google.maps.SymbolPath.CIRCLE,
		scale: 4,
		strokeColor: '#111472',
		strokeWeight: 2,
		strokeOpacity: 0.8
	};
	this.bounds = new google.maps.LatLngBounds();
	this.addMarker = function(location, contentString) {
		var infowindow;



		var marker = new google.maps.Marker({
			position: location,
			map: map,
			icon: this.icon,
		});

		this.markers.push(marker);

		if (typeof contentString !== 'undefined') {
			var infowindow = new google.maps.InfoWindow({
				content: contentString
			});

			google.maps.event.addListener(marker, 'click', function() {
				infowindow.open(map,marker);
			});
		}
	};
	this.setAllMap = function(map) {
		for (var i=0; i<this.markers.length; i++) {
			this.markers[i].setMap(map);
			this.bounds.extend(this.markers[i].getPosition());
		}
	};
	this.cleanMarkers = function() {
		this.setAllMap(null);
	};
	this.showMarkers = function() {
		this.setAllMap(this.map);
	};
	this.deleteMarkers = function() {
		this.cleanMarkers();
		this.markers = [];
		this.bounds = new google.maps.LatLngBounds();
	};
	this.setIcon = function(icon) {
		this.icon = icon;
	};
}

function getPathAsJson(shape) {
	var result = [];

	switch (shape.type) {
		case 'polygon':
			tmp = [];
			shape.getPath().forEach(function(item, index) {
				tmp.push([item.lat(), item.lng()]);
			});
			result.push(tmp);
			break;
		case 'circle':
			var center = { lat: shape.getCenter().lat(), lng: shape.getCenter().lng() };
			// result = {center: center, radius: shape.getRadius() };
			result = null;
			break;
		default:
	}

	return JSON.stringify(result);
}

function getShapeFromStoredObject(storedShape, polyOptions) {
	var newShape;

	switch (storedShape.geofence_type) {
		case 'polyline':
			var path = [];
			$.each(storedShape.path, function(index, value) {
				path.push(new google.maps.LatLng(value.lat, value.lng));
			});

			polylineOptions = polyOptions;
			polylineOptions.path = path;
			polylineOptions.strokeWeight = 6;
			polylineOptions.strokeOpacity = 0.6;
			newShape = new google.maps.Polyline(polylineOptions);
			newShape.type = 'polyline';
			newShape.bounds = newShape.my_getBounds();
			break;
		case 'polygon':
			var path = [], pathLength = storedShape.path.length, value;
			for (var i=0; i<pathLength; i++) {
				value = storedShape.path[i];
				path.push(new google.maps.LatLng(value.lat, value.lng));
			}

			var polygonOptions = polyOptions;
			polygonOptions.path = path;
			newShape = new google.maps.Polygon(polygonOptions);
			newShape.type = 'polygon';
			newShape.bounds = newShape.my_getBounds();
			break;
		case 'circle':
			var path = [];
			var circleOptions = polyOptions;
			circleOptions.center = new google.maps.LatLng(storedShape.lat, storedShape.lon);
			circleOptions.radius = storedShape.radius;
			newShape = new google.maps.Circle(circleOptions);
			newShape.type = 'circle';
			newShape.bounds = newShape.getBounds();
			break;
		case 'rectangle':
			var path = [];
			var rectangleOptions = polyOptions;
			rectangleOptions.bounds = new google.maps.LatLngBounds(
				new google.maps.LatLng(storedShape.path.southWest.lat, storedShape.path.southWest.lng),
				new google.maps.LatLng(storedShape.path.northEast.lat, storedShape.path.northEast.lng));
			newShape = new google.maps.Rectangle(rectangleOptions);
			newShape.type = 'rectangle';
			newShape.bounds = newShape.getBounds();
			break;
		case 'marker':
			var latLng = new google.maps.LatLng(storedShape.path.lat,storedShape.path.lng);
			var markerOptions = polyOptions;
			markerOptions.position = latLng;
			var newShape = new google.maps.Marker(markerOptions);
			newShape.type = 'marker';
			var bounds = new google.maps.LatLngBounds();
			newShape.bounds = bounds.extend(newShape.getPosition());
			break;
		default:
	}

	return newShape;
}

google.maps.Polygon.prototype.my_getBounds=function(){
    var bounds = new google.maps.LatLngBounds()
    this.getPath().forEach(function(element,index){bounds.extend(element)})
    return bounds;
}

google.maps.Polyline.prototype.my_getBounds=function(){
	var bounds = new google.maps.LatLngBounds();
    this.getPath().forEach(function(element,index){bounds.extend(element)})
	return bounds;
}

function OwlMapsShape () {
	this.shape;
	this.polyOptions = {
		editable: false,
		strokeColor: '#111472',
		strokeWeight: 2,
		strokeOpacity: 0.7,
		fillColor: "#111472",
        fillOpacity: 0.30,
	};
	this.fromJson = function (object, options) {
		var path = [], shape;

		// console.log(options);

		switch (object.geofence_type) {
			case 'polygon':
				$.each(object.path[0], function(index, value) {
					path.push(new google.maps.LatLng(value[0], value[1]));
				});

				var polygonOptions  = this.polyOptions;
				$.extend(polygonOptions, options);
				// console.log(path);

				polygonOptions.path = path;

				this.shape        = new google.maps.Polygon(polygonOptions);
				this.shape.type   = 'polygon';
				this.shape.bounds = this.shape.my_getBounds();
				break;
			case 'circle':
				var circleOptions = this.polyOptions;
				$.extend(circleOptions, options);

				circleOptions.center = new google.maps.LatLng(object.lat, object.lon);
				circleOptions.radius = parseFloat(object.radius);

				this.shape        = new google.maps.Circle(circleOptions);
				this.shape.type   = 'circle';
				this.shape.bounds = this.shape.getBounds();
				break;
			default:
				break;
		}
	};
	this.setMap = function(map, centered) {
		var zoom;
		this.shape.setMap(map);

		centered = (typeof centered != 'undefined') ? centered : true;
		if (centered === true) {
			map.fitBounds(this.shape.bounds);
		}

		zoom = map.getZoom();
		map.setZoom(zoom > 13 ? 13 : zoom);

		if (this.shape.type == 'polyline') {
			if (typeof markerHandler !== 'undefined') {
				this.shape.getPath().forEach(function(item, index) {
					markerHandler.addMarker(item);
				});

				markerHandler.setAllMap(map);
			}
		}
	};
}

function OwlMapsBasis (canvas) {
	this.canvas = canvas;
	this.map;
	this.mapOptions = {
		center : new google.maps.LatLng(-33.448575, -70.661376),
		zoom   : 12,
		panControl: false, 
		rotateControl: false,
		zoomControlOptions: { 
			style: google.maps.ZoomControlStyle.SMALL, 
			position: google.maps.ControlPosition.TOP_RIGHT 
		}
	};
	this.init = function() {
		this.map = new google.maps.Map(
			document.getElementById(this.canvas),
			this.mapOptions
		);
	};
	// Create the search box and link it to the UI element.
	this.createSearchBox = function() {
		var markers = [];
		var input = document.getElementById('pac-input');
		var nmap = this.map;

		nmap.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

		var searchBox = new google.maps.places.SearchBox(input);

		// Bias the SearchBox results towards current map's viewport.
  		nmap.addListener('bounds_changed', function() {
  		  searchBox.setBounds(nmap.getBounds());
  		});
	
  		searchBox.addListener('places_changed', function() {
			var places = searchBox.getPlaces();

			if (places.length == 0) {
				return;
			}

			markers.forEach(function(marker) {
		      marker.setMap(null);
		    });

			// For each place, get the icon, place name, and location.
			markers = [];
			var bounds = new google.maps.LatLngBounds();
			places.forEach(function(place) {
				var image = {
					url: place.icon,
					size: new google.maps.Size(71, 71),
					origin: new google.maps.Point(0, 0),
					anchor: new google.maps.Point(17, 34),
					scaledSize: new google.maps.Size(25, 25)
				};

				// Create a marker for each place.
				markers.push(new google.maps.Marker({
			        map: nmap,
			        icon: image,
			        title: place.name,
			        position: place.geometry.location
			      }));

				if (place.geometry.viewport) {
			        // Only geocodes have viewport.
			        bounds.union(place.geometry.viewport);
			      } else {
			        bounds.extend(place.geometry.location);
     			 }
			});
			nmap.fitBounds(bounds);
			var zoom = nmap.getZoom();
			nmap.setZoom(zoom > 15 ? 15 : zoom);
		});
	};

	// call constructor.
	this.init();
}