((function(global) {
'use strict';

    // Constructor
    var oCompass = function(args) {

        var self        = this;

        // Paramotros
        self.container  = 'compass';
        self.size       = 100;
        self.needle     = {
            img    : null,
            width  : 9,
            height : 67
        };
        self.images     = {
            needle    : '/img/compass_pointer.png',
            backgound : '/img/compass_back.png'
        };

        if ( typeof args === 'object' ){
            self.container  = args.container || self.container;
            self.size       = args.container || self.size;
            self.needle     = {
                width  : args.needle.width      || self.needle.width,
                height : args.needle.height     || self.needle.height
            };
            self.images     = {
                needle    : args.images.needle      || self.images.needle,
                backgound : args.images.backgound   || self.images.backgound
            };
        }

        self.canvas     = null;
        self.background = null;
        self.ctx        = null;
        self.degrees    = 0;

        return self;

    }

    oCompass.prototype = {

        // Limpia el canvas
        clearCanvas : function() {

            var self = this;

            self.ctx.clearRect(0, 0, self.size, self.size);

            return self;
        },


        draw : function(degrees) {

            var self = this;

            if ( self.canvas == null ){
                console.error('Target not found');
                return self;
            }

            self.degrees = ( parseInt(degrees) || 0 ) % 360;

            self.clearCanvas();
            self.ctx.drawImage(self.background, 0, 0);
            self.ctx.save();

            self.ctx.translate(
                (self.size/2),
                (self.size/2)
            );
            self.ctx.rotate(self.degrees * (Math.PI / 180));
            self.ctx.drawImage(
                self.needle.img,
                -(self.needle.width/2),
                -(self.needle.height/2)
            );

            self.ctx.restore();

            return self;
        },

        init : function() {

            var self   = this;

            self.canvas = document.getElementById(self.container);

            if ( self.canvas == null ){
                console.error('Target not found');
                return self;
            }

            if ( self.ctx !== null){
                return self;
            }

            if (self.canvas.getContext('2d')) {

                self.ctx = self.canvas.getContext('2d');

                self.needle.img     = new Image();
                self.needle.img.src = self.images.needle;

                self.background        = new Image();
                self.background.src    = self.images.backgound;
                self.background.onload = self.backgroundLoaded;

            } else {
                console.error("Canvas not supported!");
            }

            return self;
        }
    }


    oCompass.init = function() {

        var obj = new oCompass();

        obj.init();

        return obj;
    }

    oCompass.init.prototype = oCompass.prototype;

    global.oCompass = oCompass.init;

})(window));

/**
 * Ejemplo de inicialización con valores por defecto:
 *
 * var compass = oCompass();
 *
 * Ejemplo de inicialización con valores personalizados
 *
 *  var compass = oCompass({
 *     container: 'compass',
 *     size     : 100,
 *     needle   : {
 *         width : 9,
 *         height : 67
 *     },
 *     images : {
 *         needle : '/compass_pointer.png',
 *         backgound : '/compass_back.png'
 *     }
 * });
 *
 * Ejemplo de dibujo de 10 grados en sentido horario
 *
 *  compass.draw(10);
 */